import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Newsletter from '../../components/Newsletter'
import imageGraphqlStack from '../../images/projects/graphqlstack.png'
import GitHub from '../../images/github.svg'
import ProjectImage from '../../atoms/ProjectImage'
import ProjectLinks from '../../atoms/ProjectLinks'

export default function ProjectGraphqlStack({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Projects | GraphQLStack.com"
        keywords={[`projects`, `python`, `javascript`, `react`, 'open source']}
      />
      <section>
        <article>
          <h1>GraphQLStack.com</h1>
          <a href="https://graphqlstack.com">
            <ProjectImage
              src={imageGraphqlStack}
              alt="Graphqlstack.com screenshot"
            />
          </a>
          <h2>Goal</h2>
          <p>
            I built <a href="https://graphqlstack.com">graphqlstack.com</a> as a
            resource to help people new to GraphQL learn what its various layers
            are, and how they connect with each other. The layers and tools are
            organized visually in distinct sections, and arranged in a vertical
            stack representing how they would actually be deployed and
            connected. Each tool includes links to its homepage and GitHub repo,
            making this a valuable resource for anyone interested in learning
            how to build a GraphQL stack, big or small.
          </p>
          <p>
            Even though the majority of the GraphQL ecosystem currently revolves
            around JavaScript, I made sure to include libraries and resources
            for other languages, and let the user select their language of
            choice via dropdowns.
          </p>
          <h2>Technologies</h2>
          <p>
            The data is driven by a single JSON file, which the React app parses
            to render both the sections (e.g. Clients, Gateways, Servers) and
            the tools per section. Using a single JSON file made it easy for
            contributors to add additional tools.
          </p>
          <p>
            Flexbox was used to build a seamless responsive design that works
            equally well on mobile devices and tablets as it does on the
            desktop.
          </p>
        </article>
      </section>
      <ProjectLinks>
        <a href="https://graphqlstack.com">🚀 Visit project</a>
        <a href="https://github.com/steven-mercatante/graphql-stack">
          <GitHub height="26" />
          &nbsp; View source code
        </a>
      </ProjectLinks>
      <Newsletter
        body="Subscribe to be notified when I publish new content and release new projects."
        referrer="projects - graphqlstack"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectGraphqlstack {
    site {
      siteMetadata {
        title
      }
    }
  }
`
