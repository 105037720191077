import styled from 'styled-components'

const ProjectLinks = styled.div`
  margin: 40px 0;
  font-size: 1.6rem;

  a {
    display: block;
    margin-bottom: 20px;
  }

  a:hover {
    color: #ee5253 !important;
  }
`

export default ProjectLinks
