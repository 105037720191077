import styled from 'styled-components'
import ResponsiveImage from './ResponsiveImage'

const ProjectImage = styled(ResponsiveImage)`
  margin: 20px 0;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
`

export default ProjectImage
